import { $ } from "~/zeus";
import { typedGql } from "~/zeus/typedDocumentNode";
import { FallbackLocaleInputType } from "~/zeus";

const BannerSectionQuery = typedGql("query")({
  GetBannerSection: [
    {
      locale: $("locale", "LocaleInputType"),
      fallbackLocale: FallbackLocaleInputType.zh,
      limit: $("limit", "Int"),
      where: $("where", "Banner_where"),
      sort: $("sort", "String"),
      page: $("page", "Int"),
      slug: $("slug", "String"),
    },
    {
      totalDocs: true,
      totalPages: true,
      docs: {
        id: true,
        name: true,
        bannerStartDate: true,
        bannerEndDate: true,
        _locations: true,
        region: true,
        desktop: [
          {},
          {
            id: true,
            url: true,
            sizes: {
              large: {
                url: true,
              },
            },
          },
        ],
        mobile: [
          {},
          {
            id: true,
            url: true,
            sizes: {
              large: {
                url: true,
              },
            },
          },
        ],
        link: true,
        external: true,
      },
    },
  ],
});

export default BannerSectionQuery;
